'use strict'
import '../../styles/blocks/_page-hero.scss'

export const playHeroLottie = () => {
    const pageHero = $('.js-page-hero')

    const noAnimation = ['puzzle', 'career', 'real-estate','working-capital', 'investment-financing','agriculture'];

    if (pageHero && pageHero.length > 0) {
        // This property allows us to know which assets to load, based on provided value
        const lottieToLoad = pageHero.data('lottie')

        if (lottieToLoad && !noAnimation.includes(lottieToLoad)) {
            // Import the lottie animation for the selected illustration
            import(/* webpackChunkName: "lottie-data" */`../lottie-animations/${lottieToLoad}`).then(module => {
                let animationData = module.getData()
                let lottieOptions = module.getOptions(animationData)

                if (animationData !== 'undefined') {
                    // If the animation data exists, load the lottie library and play the animation
                    import(/* webpackChunkName: "hero-lottie-animation" */'lottie-web/build/player/lottie_svg.min.js').then(module => {
                        const anim = module.loadAnimation(lottieOptions);
                    })
                }
            })
        }
    }
}

$(window).on('load', function () {
    playHeroLottie()
});


